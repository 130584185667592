@keyframes SystemFadeSpinner {
	50% {
		opacity: 0.3
	}

	100% {
		opacity: 1
	}
}

@keyframes SystemDotsLoader {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

html {
	font-size: 14px;
}

#root {
	height: 100%;
	border: 1px solid transparent;
}

.notistack-SnackbarContainer.SnackbarContainerOffset {
	right: 8px;
	bottom: 2px;
	max-width: calc(100% - 16px);
}

.react-datepicker-popper {
	z-index: 3;
}